// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-study-pages-js": () => import("../src/templates/case-study-pages.js" /* webpackChunkName: "component---src-templates-case-study-pages-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-work-js": () => import("../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

